.AuthenticationPromptContainer {
  position:fixed;
  padding:0;
  margin:0;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
}

.AuthenticationPrompt {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 30%;
  box-shadow: 10px 10px 10px #c0c0c0;
  border-radius: 5%;
  background: #96A393;
}

.SocialAuthButtons {
  display: flex;
}

.SocialAuthButtons button {
  margin-left: 10px;
  margin-right: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.Countdown {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  margin-top: 25vh;
  margin-bottom: 15vh;
  padding: 13vmin 5vw;
  width: 50%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  border: 1.5px solid #000000;
}

@media only screen and (max-device-width: 600px) {
  .Countdown {
    width: 80% !important;
  }
}

.Countdown h2 {
  color: #445137;
}

.Countdown p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #3F423C;
}

.CountdownSection {
  background-image: url('./background-1k.png');
}

@media only screen and (max-width: 1000px) {
  .CountdownSection {
    background-image: url('./background-1k.png') !important;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 3999px) {
  .CountdownSection {
    background-image: url('./background-2k.png') !important;
  }
}

@media only screen and (min-width: 4000px) {
  .CountdownSection {
    background-image: url('./background-4k.png') !important;
  }
}

#wedding-date {
  font-size: 32px;
}

.SectionPart {
  font-size: large;
  /*padding: 32px 100px;*/
  padding-top: 32px;
  padding-bottom: 32px;
  width: 33vw;
  text-align: center;
}

@media only screen and (max-device-width: 600px) {
  .SectionPart {
    width: 100vw !important;
  }
}

.SectionPart:hover, .SectionPart:active {
  background-color: white;
}

.SectionPart:hover h3, .SectionPart:active h3{
  color: black;
  text-decoration: underline;
  text-decoration-color: #703A35;
  text-decoration-thickness: 3px;
}

.SectionPart h3 {
  text-align: center;
  font-weight: bold;
  font-size: 23px;
  line-height: 31px;
}

.Sections {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: #F7F4EF;
}


@media only screen and (max-device-width: 600px) {
  .Sections {
    flex-direction: column !important;
  }
}

.Sections a {
  text-decoration: none;
  color: #3F423C;
}

.Separator {
  background: #E9E9E9;
  height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #703A35;
}

.Separator img {
  margin: 0 3%;
  height: 50%;
}

.OurMessage {
  background: #F8F6F2;
  font-size: calc(10px + 2vmin);
  color: white;
}

.OurMessageInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

@media only screen and (max-device-width: 600px) {
  .OurMessageInner {
    flex-direction: column !important;
  }
}

.OurMessageText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 58px 24px;
  margin-left: 56px;
  width: 47vw;

  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  font-family: Handlee;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: black;
}

@media only screen and (max-device-width: 600px) {
  .OurMessageText {
    width: 70vw !important;
    padding: 0px !important;
    margin-left: 5px !important;
  }
}

.OurMessageTextInner {
  margin: 0px 56px;
}


.OurMessageTextSignature {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: right;
}

.SnappertunaBanner {
  background-image: url('./calliola-above.jpg');
  background-size: cover;
}

.SnappertunaBanner a {
  text-decoration: none;
  width: 95%;
  height: 95%;
}

.SnapBannerInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.SnapBannerInner:hover,
.SnapBannerInner:active {
  background: rgba(255, 255, 255, 0.7);
}

.SnapBannerInner:hover p {
  text-decoration-line: underline;
  color: #703A35;
}


.SnapBannerInner h2 {
  font-family: Playball;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 59px;
  margin-bottom: 20px;

  color: #445137;
}

.SnapBannerInner p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  margin-top: 0px;
  margin-bottom: 50px;
  text-align: center;

  color: #000000;
}

.RSVP {
  background: #F8F6F2;
  font-size: calc(10px + 2vmin);
  padding: 32px 44px;
  color: white;
}

.RSVPContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 44px;
  width: 90vw;

  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  color: black;
}

.RSVPContent h2 {
  font-size: 47px;
  font-family: Playball;
  font-style: normal;
  font-weight: normal;
}

.RSVPParagraphs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#rsvp-separator {
  margin-bottom: 30px;
}

.RSVPParagraphs p {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.RSVPContent button {
  margin: 0;
  padding: 0;
  font-size: 18px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;

  background: #703A35;
  border-radius: 8px;
  font-size: 16px;

  margin: 25.5px 0px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.RSVPContent a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.RSVPContent button:hover {
  cursor: pointer;
  background: #8A5853;
}

.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Footer img {
  width: 100%;
  height: 10vh;
  opacity: 0.8;
  object-fit: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}