
.VenueSectionImage {
  background-image: url('./calliola-barn.jpg');
  width: 60%;
  height: 120vh;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-device-width: 600px) {
  .VenueSectionImage {
    width: 100% !important;
    height: 50vh;
  }
}

.VenueSectionIntro {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 10vh;
  width: 40%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-device-width: 600px) {
  .VenueSectionIntro {
    width: 60% !important;
    height: 60vh;
  }
}

.VenueSection {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

@media only screen and (max-device-width: 600px) {
  .VenueSection {
    flex-direction: column-reverse !important;
  }
}

.VenueTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.VenueTitleBalls {
  height: 9px;
  margin: 30px;
}

.GoodToKnow {
  background: rgba(249, 246, 241, 0.8);
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: left !important;
  padding: 30px 40px;
}

.GoodToKnow h2 {
  font-family: Playball;
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 0px;
}

.GoodToKnow b {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 37px;
}

.GoodToKnow li {
  list-style-image: url('./black-flower.svg');
  list-style-position: outside;
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}