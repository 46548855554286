body {
  font-family: 'Open Sans';
}

.Header {
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 5vh;
  display: flex;
  font-size: calc(10px + 1vmin);
  background: rgba(255, 255, 255, 0.6);
}

.Header p {
  margin: auto 0px;
}

#header-title {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-left: calc(10px + 1vw);
  color: white;
  background: rgb(75, 94, 71, 0.6);
  align-items: center;
}

@media only screen and (max-device-width: 600px) {
  #header-title {
    width: 80% !important;
  }
}

#header-menu {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: calc(10px + 1vw);
}

@media only screen and (max-device-width: 600px) {
  #header-menu {
    width: 20% !important;
  }
}

#header-icon {
  width: 35px;
  height: 35px;
  margin-right: 22px;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

/* Defines a section of the website
   that's meant to be the intro. It will usually
   contain a big background picture.
*/
.Intro {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: inherit;
  min-height: 85vh;

  background-size: cover;
  background-position-y: center;
}

.Intro h2 {
  font-size: 60px;
  font-family: Playball;
  font-style: normal;
  font-weight: normal;
  margin: 8px 0px;
}

.WeddingInfoSection {
  min-height: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
