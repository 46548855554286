.ScheduleSection {
    width: 100%;
    background-image: url("schedule-background.png");
    background-size: cover;
    background-position: top;
    justify-content: center;
    padding-bottom: 150px;
    padding-top: 150px;
}

.ScheduleSectionInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 80px 100px;
    background: rgba(249, 246, 241, 0.8);
    border-radius: 8px;
    width: 40%;
    height: 80%;
}

.ScheduleSectionInner table {
    padding-top: 68px;
    padding-bottom: 68px;
}

.ScheduleSectionInner td {
    padding-bottom: 20px;
}

.schedule-time {
    text-align: left;
    font-weight: bolder;
    width: 40%;
}

.schedule-activity {
    width: 60%;
    text-align: left;
}

@media only screen and (max-device-width: 600px) {
    .ScheduleSectionInner {
        padding: 5%;
        margin: 5%;
        width: 80%;
    }
    .ScheduleSectionInner table {
        width: 100%;
    }
    .schedule-time {
        width: 30% !important;
    }
    .schedule-activity {
        width: 70% !important;
    }
}