body {
  overflow-x: hidden;
}

.AccommodationSection {
    background-image: url('./accommodation-room.jpg');
    height: 1024px !important;
}

.AccommodationInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 180px;
    padding: 42px;

    position: static;
    height: 568px;
    left: 0px;
    top: 179px;

    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
}

@media only screen and (max-device-width: 600px) {
  .AccommodationInner {
    width: 90%;
  }
}

.AccommodationTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.AccTitleBalls {
  height: 9px;
  margin: 30px;
}

@media only screen and (max-device-width: 600px) {
  .AccTitleBalls {
    display: none;
  }
}

.AccomodationDetails {
  padding: 32px;
  background: rgba(249, 246, 241, 0.8);
}

.AccomodationDetails h2 {
  font-size: 32px;
  font-family: Playball;
  color: #445137;
  font-weight: normal;
}

@media only screen and (max-device-width: 600px) {
  .AccommodationTitle h2 {
    font-size: 50px !important;
  }
  .AccommodationInner {
    padding: 5%;
  }
}

.AccomodationDetails p {
  font-size: 18px;
}