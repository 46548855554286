.InvitationSection {
    width: 100%;
    height: 1000px;
    display: flex;
    flex-direction: row;
}

.InvitationImage {
    background: url("./engagement.png");
    background-size: cover;
    background-position: center;
    width: 50vw;
    height: 100%;
}

@media only screen and (max-device-width: 600px) {
  .InvitationImage {
    width: 0% !important;
  }
}

.InvitationContent {
    width: 50vw;
    height: 100%;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    text-align: center;
}

@media only screen and (max-device-width: 600px) {
  .InvitationContent {
    width: 100% !important;
  }
}

.InvitationContent h2 {
    font-family: Playball;
    font-weight: lighter;
    font-size: 32px;
    color: #445137;
}

.InvitationContent h3 {
    font-family: Playball;
    font-size: 25px;
    color: #445137;
    font-weight: lighter;
}

.InvitationContent hr {
    width: 100%;
}

.InvitationContent p {
    font-size: 17px;
    margin-left: 10%;
    margin-right: 10%;
}

.PlantSeparator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

#plantsep {
    width: 20%;
}

#hearticon {
    font-size: 32px;
}